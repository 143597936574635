<template>
  <span v-html="translatedHtml"></span>
</template>

<script setup>
const props = defineProps({
  tKey: {
    type: String,
    required: true,
  },
  varsMap: {
    type: Object,
    required: false,
  },
});

const { t } = useI18n();

const translatedHtml = computed(() => {
  if (!props.tKey) return '';
  if (!props.varsMap) return t(props.tKey);
  return t(props.tKey, props.varsMap);
});
</script>
